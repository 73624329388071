import * as React from "react"
import { graphql, Link } from "gatsby"
import {GatsbyImage} from 'gatsby-plugin-image'

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = ({data}) => {
   	const mapData: Array<any> = data.allFile.nodes
	const thumbs = mapData.map((arNode)=>(
		<div 
		className="mb-5 relative"
		style={{breakInside: "avoid"}}
		>
			<GatsbyImage image={arNode.childImageSharp.gatsbyImageData} alt={arNode.relativeDirectory}/>
			<div className="hidden bg-black text-gray-200 p-2 text-xs font-bold uppercase bg-opacity-70 absolute bottom-0 left-0 right-0">
				<h2>{/**(arNode.name as String).replace(/_/g," ")**/}</h2>
			</div>
		</div>
	) )   


   return(
  <Layout>
    <h3 className="text-sm py-6 font-bold uppercase text-gray-600">Latest Projects</h3>
    <div>


  <div id="infinite" className="gap-x-5 ig-sm md:ig-md lg:ig-lg">
  
    
  {thumbs}
  
  </div>

    </div>
  </Layout>
)}

export const query = graphql`query{
  allFile(
    filter: {sourceInstanceName: {eq: "images"}, relativeDirectory: {eq: "home"}}
  ) {
    nodes {
      name
      sourceInstanceName
      relativeDirectory
      childImageSharp {
        gatsbyImageData(
           width: 300
           placeholder: BLURRED
        )
      }
    }
  }
}
`

export default IndexPage
